import * as yup from 'yup';
import {
  getPasswordRegex,
  // __,
  // isZipCodeArraySyntaxValid,
  // config,
  // contactManagerIdPattern,
} from '@adac/core-model';


import {
  AddressSchema, PublicPhoneValidation, PrivatePhoneValidation, passwordErrorMessage,
} from '@adac/core-view';

export const BasicCompanyFormSchema = AddressSchema.shape({
  // contactManagerId: yup.string().matches(contactManagerIdPattern, `${__('Contact manager id is not valid')}`).max(30).required(),
  name: yup.string().required(),
  type: yup.string().required(),
  zip: yup
    .string()
    .nullable()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly fix digits')
    .max(5, 'Must be exactly fix digits'),
  // .required('CompanyZipNotProvided'),
  place: yup
    .string().nullable(),
  street: yup
    .string().nullable(),
  houseNumber: yup
    .string().nullable(),
  latitude: yup
    .number().nullable(),
  longitude: yup
    .number().nullable(),
  confidence: yup.number(),
  activeFrom: yup.date().nullable(),
  activeTo: yup.date().nullable(),
  isScheduled: yup.boolean().nullable(),
  // user
  username: yup.string().email().required(),
  password: yup.string().matches(
    getPasswordRegex(),
    passwordErrorMessage,
  ),
  confirmPassword: yup.string().oneOf([yup.ref('password')]),
  // driver
  familyName: yup.string().required(),
  firstName: yup.string().required(),
  validatePhone: yup.boolean(),
  publicPhone: PublicPhoneValidation,
  privatePhone: PrivatePhoneValidation,
});

export const CreateCompanySchema = BasicCompanyFormSchema.shape({
  password: yup.string().required().matches(
    getPasswordRegex(),
    passwordErrorMessage,
  ),
  confirmPassword: yup.string().oneOf([yup.ref('password')]).required(),
});


export const EditCompanySchema = BasicCompanyFormSchema;
