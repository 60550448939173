/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  List, Datagrid, TextField,
  Pagination, FunctionField,
} from 'react-admin';
import styled from 'styled-components';

import { View } from '@adac/core-view';
import {
  Company,
  __,
  config,
} from '@adac/core-model';

import CompanyFilter from './CompanyFilter';
import { CompanyDriverListButton } from './CompanyDriverListButton';
import { GenericListActions } from '../common/GenericListActions';
import { NotificationSettingsButton } from './NotificationSettingsButton';
import { EditButtonWithRole } from '../common/react-admin/EditButtonWithRole';

export const CompanyListStyles = styled(View)`
  &>*:first-child {
    margin-bottom: 24px;
  }

  .order-id {
    background-color: ${props => props.theme.colors.info};
    color: ${props => props.theme.colors.background};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;

  }
`;

const sndCompanyRowStyle = (record: any) => ({
  backgroundColor: record.active ? 'white': '#ffe6e6',
});

export const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150, 200]} {...props} />;


const CompanyList = (props: any) => (
  <CompanyListStyles>
    <List
      {...props}
      bulkActionButtons={false}
      actions={<GenericListActions allowCreate={false} />}
      filters={<CompanyFilter />}
      filterDefaultValues={{ active: true }}
      pagination={<PostPagination />}
    >
      <Datagrid
        rowStyle={sndCompanyRowStyle}
      >
        <TextField
          source="id"
          label={__('Partner ID')}
          sortable
        />
        <TextField
          source="contactManagerId"
          label={__('Contact Manager ID')}
          sortable
        />
        <TextField
          source="name"
          label={__('Company Name')}
          sortable
        />
        <TextField
          source="typeName"
          label={__('Company type')}
          sortable
        />
        <TextField
          source="dispatcherRole"
          label={__('Rolle')}
          sortable={false}
        />
        <TextField
          source="dispatcherName"
          label={__('Company Boss or dispatcher name')}
          sortable={false}
        />
        <TextField
          source="dispatchTargetAddress"
          label={__('DispatchTarget')}
          sortable={false}
        />
        <NotificationSettingsButton history={props.history} />
        <CompanyDriverListButton history={props.history} />
        <EditButtonWithRole label={__('Edit')} />

        <FunctionField
          label={__('createdAt')}
          render={(record: Company) => config.time(record.createdAt)}
          sortable
        />

        <FunctionField
          label={__('updatedAt')}
          render={(record: Company) => config.time(record.updatedAt)}
          sortable
        />
      </Datagrid>
    </List>
  </CompanyListStyles>
);

export default CompanyList;
